import { ComponentRef, ErrorHandler, Injectable, Injector } from '@angular/core'
import { ErrorModalComponent } from './_shared/components/misc/error-modal/error-modal.component'
import { DynamicRefService } from './_shared/services/dynamic-ref-service/dynamic-ref.service'
import { HttpErrorResponse, HttpResponse } from '@angular/common/http'
import { Router } from '@angular/router'
import { ToastService } from './_shared/services/toast-service/toast.service'
import { getSync } from 'stacktrace-js'
import { LogService } from '@awork/_shared/services/log-service/log.service'
import { classifyError, ErrorType } from '@awork/_shared/functions/classify-error'
import { ModalService } from './_shared/services/modal-service/modal.service'

@Injectable({ providedIn: 'root' })
export class AppErrorHandler implements ErrorHandler {
  constructor(
    private dynRefSvc: DynamicRefService,
    private injector: Injector,
    private logService: LogService
  ) {}

  private modal: ErrorModalComponent | null
  private modalRef: ComponentRef<ErrorModalComponent> | null
  private expectedErrors: { message?: string; reason: string; action?: Function; matches?: Function }[] = [
    {
      message: `You provided 'null' where a stream was expected`,
      reason: `LEAKING_SUBSCRIPTION`
    },
    {
      message: 'The selector "aw-app-root" did not match any elements',
      reason: `UNSUPPORTED_BROWSER`
    },
    {
      message: 'Uncaught (in promise): [object Undefined]',
      reason: 'ADBLOCK'
    },
    {
      message: 'math.pow is not a function',
      reason: 'LOTTIE'
    },
    {
      matches: (error: Error) =>
        (error.stack && error.stack.includes('@aspnet/signalr')) ||
        (error.message &&
          (error.message.includes('Error parsing handshake response') ||
            error.message.includes('WebSocket') ||
            error.message.includes('at new HttpError') ||
            error.message.includes('Server returned an error on close') ||
            error.message.includes('Server timeout elapsed without receiving a message') ||
            error.message.includes(`Cannot send data if the connection is not in the 'Connected' State`))) ||
        this.matchStackFrame('@aspnet/signalr'),
      reason: 'SIGNALR'
    },
    {
      matches: (error: Error) => error.message?.includes('ChunkLoadError'),
      reason: 'NEW_DEPLOYMENT',
      action: () => window.location.reload()
    },
    {
      message: 'Cannot parse url',
      reason: 'MANUAL_URL_EDIT',
      action: () => {
        const router: Router = this.injector.get(Router)
        router.navigate(['/404'], { skipLocationChange: true })
      }
    },
    {
      matches: (error: Error) =>
        error.message?.includes('REPLY_TIMEOUT') || error.message?.includes('Action timed out'),
      reason: 'REPLY_TIMEOUT'
    },
    {
      matches: (error: Error) =>
        error.stack?.includes('@angular/core') && error.message?.includes(`Cannot read property 'context' of null`),
      reason: 'ANGULAR_INTERNAL_ERROR'
    },
    {
      matches: (error: Error) =>
        error.message?.includes(`Internal error committing transaction.`) ||
        error.message?.includes(`DataError: Failed to write blobs`),
      reason: 'LOCAL_STORAGE_INTERNAL_ERROR'
    },
    {
      matches: (error: Error) =>
        error.stack?.includes('chargebee.js') ||
        (error.stack?.includes('chargebee') && error.message?.includes('ChunkLoadError')),
      reason: 'CHARGEBEE_INTERNAL_ERROR'
    },
    {
      matches: (error: Error) =>
        (error.message?.includes('undefined is not an object (evaluating ') &&
          error.message?.includes(`clientHeight`)) ||
        error.stack?.includes('smoothscroll.js') ||
        error.stack?.includes('hasScrollableSpace'),
      reason: 'SMOOTHSCROOLL_INTERNAL_ERROR'
    },
    {
      matches: (error: Error) => error.stack?.includes('medium-editor.js'),
      reason: 'MEDIUMEDITOR_INTERNAL_ERROR'
    },
    {
      matches: (error: Error) =>
        // https://sentry.bwork.io/organizations/hqlabs/issues/716/
        // https://github.com/angular/angular/issues/31684
        error.stack?.includes('errors occurred during unsubscription') ||
        error.message?.includes('errors occurred during unsubscription'),
      reason: 'ANGULAR_ZONE_ERROR'
    },
    {
      matches: (error: Error) =>
        error.stack?.includes('AsyncAction') || error.message?.includes('executing a cancelled action'),
      reason: 'ANGULAR_ASYNC_ACTION_ERROR'
    },
    {
      message: 'chargebee',
      reason: 'CHARGEBEE_INTERNAL_ERROR'
    },
    {
      matches: (error: Error) => error instanceof HttpErrorResponse || error instanceof HttpResponse,
      reason: 'HTTP_ERROR_RESPONSE'
    },
    {
      message: 'HttpErrorResponse',
      reason: 'HTTP_ERROR_RESPONSE'
    },
    {
      matches: (error: Error) =>
        error instanceof TypeError &&
        (error.message.includes('Failed to fetch') ||
          error.message.includes('Abgebrochen') ||
          error.message.includes('Die Netzwerkverbindung wurde unterbrochen')),
      reason: 'NETWORK_FAILURE',
      action: () => {
        const toastService: ToastService = this.injector.get(ToastService)
        toastService.show(q.translations.AppErrorHandler.networkFailure, {
          action: () => window.location.reload(),
          actionText: q.translations.AppErrorHandler.reloadPage
        })
      }
    },
    {
      matches: (error: Error) =>
        error.message &&
        (error.message.includes('QuotaExceededError') ||
          (error.message.includes('exceeded') && error.message.includes('quota'))),
      reason: 'FULL_STORAGE'
    },
    {
      message: 'responseText is only available if responseType is',
      reason: 'FIREFOX RESPONSE ERROR'
    },
    {
      message: 'Uncaught (in promise): [object Window]',
      reason: 'VENDOR_ERROR'
    },
    {
      matches: (error: Error) =>
        error.message?.includes('Outlet is not activated') ||
        error.message?.includes('Cannot activate an already activated outlet'),
      reason: 'ROUTER_ERROR'
    },
    {
      message: 'mutation operation was attempted on a database',
      reason: 'FIREFOX_INDEXEDDB'
    },
    {
      message: 'InvalidStateError: The object is in an invalid state',
      reason: 'SAFARI_INVALID_STATE'
    },
    {
      matches: (error: Error) =>
        error.message?.includes('DataError') ||
        error.message?.includes('Failed to write blobs') ||
        error.message?.includes('IDBDatabase'),
      reason: 'DB_ERROR'
    },
    {
      matches: (error: Error) =>
        error.message?.includes('Illegal invocation') &&
        (error.stack?.toLowerCase().includes('beacon') ||
          error.stack?.includes('bat.js') ||
          error.stack?.includes('sendLogDNA')),
      reason: 'SEND_BEACON_ERROR'
    }
  ]

  public handleError(error: Error | any): void {
    // TODO: Clean up the types here by investigating when, why, and how zone.js
    // wraps the actual error as `originalError`, and which one we should actually log
    error = error ? error.originalError || error : null

    // Filter expected errors, but send warning to LogDNA

    if (error?.stack || error?.message) {
      for (const expectedError of this.expectedErrors) {
        const { message, matches, reason, action } = expectedError
        if ((message && error.message && error.message.includes(message)) || (matches && matches(error))) {
          if (
            reason !== 'NEW_DEPLOYMENT' &&
            reason !== 'HTTP_ERROR_RESPONSE' &&
            reason !== 'SIGNALR' &&
            reason !== 'ADBLOCK' &&
            reason !== 'FULL_STORAGE' &&
            reason !== 'FIREFOX RESPONSE ERROR' &&
            reason !== 'ROUTER_ERROR' &&
            reason !== 'FIREFOX_INDEXEDDB' &&
            reason !== 'DB_ERROR' &&
            reason !== 'SEND_BEACON_ERROR'
          ) {
            const errorInfo =
              error instanceof HttpErrorResponse && error.headers ? error.headers.get('trace-id') : error.stack
            this.logService.sendLogDNA('WARN', `${reason}: ${errorInfo}`, error)
          }

          if (action) {
            try {
              action()
            } catch (error) {
              if (error instanceof Error) {
                this.logService.sendLogDNA(
                  'WARN',
                  `Executing an error handler action threw another error: ${error.stack}`,
                  error
                )
              }
            }
          }
          return
        }
      }

      console.error(error)

      const errorType = classifyError(error)

      const openedModals = this.getOpenedModalTitles()

      const logService: LogService = this.injector.get(LogService)
      logService.logError(error, undefined, undefined, errorType, { openedModals })

      if (errorType !== ErrorType.App) {
        return
      }

      if (this.modal && this.modalRef) {
        console.warn('Error modal is already open')
        return
      }

      // Show error modal and shake awork

      const [modalRef, modal] = this.dynRefSvc.create(ErrorModalComponent)
      this.modal = modal
      this.modalRef = modalRef

      const shakeDuration = 400
      modal.shakeDuration = shakeDuration
      modal.error = error
      this.shakeQ(shakeDuration)

      this.modal.hiding.subscribe(() => {
        this.modalRef = null
        this.modal = null
      })
    }
  }

  private shakeQ(duration: number) {
    // The Web Animations API is not fully supported in all major browsers yet
    // See: https://developer.mozilla.org/en-US/docs/Web/API/Web_Animations_API#Browser_compatibility
    if (document.body && 'animate' in document.body) {
      const transforms = [
        'translateX(-25px)',
        'translateX(25px)',
        'translateX(-25px)',
        'translateX(25px)',
        'translateX(-25px)',
        'translateX(0px)'
      ].map(transform => ({ transform }))

      document.body.animate(transforms as Keyframe[], { duration, easing: 'linear' })
    }
  }

  /**
   * Matched the filename from the error's stack frames
   * @param filename
   */
  private matchStackFrame(filename: string): boolean {
    const stackFrames = getSync() || []
    return stackFrames.some(frame => {
      return frame.fileName.includes(filename)
    })
  }

  /**
   * Gets the opened modal titles to add them to the error log as extra info
   * @returns {string[]}
   */
  private getOpenedModalTitles(): string[] {
    const modalService = this.injector.get(ModalService)
    return modalService.modals?.map(modal => modal.title) || []
  }
}
